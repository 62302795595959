import React from 'react'
import StarTutoringLayout from '../../../components/pages/StarTutoring/StarTutoringLayout'
import LineupCreatePage from '../../../components/pages/StarTutoring/LineupCreatePage'

const AssignmentLineupCreatePage = (props: any) => {
  return <StarTutoringLayout path={props.path}>
    <LineupCreatePage />
  </StarTutoringLayout>
}

export default AssignmentLineupCreatePage
